export const GLOBAL = {
    APP_ENTRY_POINT: window["apx-is"] || {},
    RECAPTCHA_SITE_KEY: "reCaptchaSiteKey",
    DEFAULT_BINDING_PREFIX: "binding",
};

export const DEFAULTS = {
    CODE_LENGTH: 4,
    CURRENCY_SIGN: "₽",
    ENTITIES_PER_PAGE: 4,
    FAVORITE_PRODUCTS_PER_PAGE: 9,
    FAVORITE_COLLECTIONS_PER_PAGE: 9,
    CHECK_STATUS_INTERVAL: 2000, // 2s
};

export const UI = {
    STICKY_HEADER_BREAKPOINT: 1024, // px
    STICKY_HEADER_MOBILE_BREAKPOINT: 370, // px
    STICKY_HEADER_HEIGHT: 80, // px
    STICKY_HEADER_MOBILE_HEIGHT: 60, // px
};

export const ROUTES = {
    API: {
        AUTH_REQUEST_CODE: "/api/auth/request_code",
        LOGIN: "/api/auth/cookie/login",
        LOGOUT: "/api/auth/cookie/logout",

        UPDATE_PROFILE: "/api/user/profile/update",
        CHANGE_PHONE_REQUEST_CODE:
            "/api/user/profile/change_phone/request_code",
        CHANGE_PHONE_COMPLETE: "/api/user/profile/change_phone/complete",

        EDIT_LEGAL_ENTITY: "/api/user/profile/entity/edit",
        DELETE_LEGAL_ENTITY: "/api/user/profile/entity/delete",
        GET_ENTITIES_LIST: "api/user/profile/entity/list",

        ADD_TO_FAVORITES: "/api/user/profile/favorites/add",
        REMOVE_FROM_FAVORITES: "/api/user/profile/favorites/remove",
        GET_FAVORITES_LIST: "/api/user/profile/favorites/list",

        ADD_TO_FAVORITE_COLLECTIONS:
            "/api/user/profile/favorite_collections/add",
        REMOVE_FROM_FAVORITE_COLLECTIONS:
            "/api/user/profile/favorite_collections/remove",
        GET_FAVORITE_COLLECTIONS_LIST:
            "/api/user/profile/favorite_collections/list",

        GET_CART_DETAILS: "/api/cart/details",
        ADD_PRODUCT_TO_CART: "/api/cart/add_product",
        SET_CART_ITEM_QUANTITY: "/api/cart/set_quantity",

        SELECT_CITY: "/api/city/select",
        APPLY_EVENT: "/api/events/apply",
        GIVE_FEEDBACK: "/api/feedback/give",
        APPLY_VACANCY: "/api/vacancies/apply",

        CALCULATE_ORDER_REQUEST: "/api/order/request/calculate",
        CREATE_ORDER_REQUEST: "/api/order/request/create",

        CALCULATE_ORDER: "/api/order/calculate",
        CREATE_ORDER: "/api/order/create",

        GET_INVOICE_STATUS: "/api/order/invoice/status",
        REGENERATE_INVOICE: "/api/order/invoice/regenerate",
    },
    PAGES: {
        HOME: "/",
        CART: "/cart",
    },
};

export const CART = {
    MAX_ITEM_QUANTITY: 9999,
};

export const PRODUCT = {
    MIN_COUNTER_QUANTITY: 1,
};

/** @enum {string} */
export const DOCUMENT_EVENTS = {
    READY: "DOMContentLoaded",
    MAP_LOADED: "MapLoaded",
};

/** @enum {string} */
export const GLOBAL_EVENTS = {
    SHOW_DIALOG: "bus:show-dialog",
    SHOW_BACKDROP: "bus:show-backdrop",
    HIDE_BACKDROP: "bus:hide-backdrop",
    WATCH_BACKDROP: "bus:watch-backdrop",
    CHANGE_BACKDROP_TEXT: "bus:change-backdrop-text",
    CHANGE_DESKTOP_SORT: "bus:change-desktop-sort",
    CHANGE_PER_PAGE: "bus:change-per-page",
    CHANGE_ENTITIES: "bus:change-entities",
    SET_VACANCY: "bus:set-vacancy",
};

/** @enum {string} */
export const SLIDER_EVENTS = {
    SLIDE: "slider:slide",
};

/** @enum {string} */
export const MODALS = {
    AUTH: "#login",
    DIALOG: "#dialog",
    CHANGE_PHONE: "#change-phone",
    LEGAL_ENTITY: "#legal-entity",
    FEEDBACK: "#feedback-form-modal",
    VACANCY: "#vacancy-form-modal",
    EVENT: "#event-form-modal",
};

/** @enum {string} */
export const SLIDERS = {
    ABOUT_HISTORY: "[data-about-history-slider]",
    PARTNERS: "[data-partners-slider]",
    PARTNERS_WRAPPER: "[data-partners-slider-wrapper]",
    ABOUT_US: "[data-about-us-slider]",
    COLLECTIONS: "[data-collections-slider]",
    EVENTS: "[data-events-slider]",
    FEATURED: "[data-featured-slider]",
    NEWS: "[data-news-slider]",
    SERVICE_REVIEWS: "[data-service-reviews-slider]",
    PICTURES: "[data-pictures-slider]",
    PRODUCTS: "[data-products-slider]",
    REVIEWS: "[data-reviews-slider]",
    PROJECTS: "[data-projects-slider]",
    PROMOTIONS_LANDING: "[data-promotions-landing-slider]",
    PROMOTIONS_PAGE: "[data-promotions-page-slider]",
    THUMBS_GALLERY: "[data-thumbs-gallery-slider]",
    BRAND_PAGE: "[data-brand-page-slider]",
    COLLECTION: "[data-collection-slider]",
    INTERIOR_GALLERY: "[data-interior-gallery-slider]",
};

/** @enum {string} */
export const VUE_ELEMENTS = {
    CART: "[data-vue-cart]",
    BACKDROP: "[data-vue-backdrop]",
    AUTH_FORM: "[data-vue-auth-form]",
    CART_WIDGET: "[data-vue-cart-widget]",
    ENTITY_FORM: "[data-vue-entity-form]",
    CHECKOUT_FORM: "[data-vue-checkout-form]",
    PRODUCT_CONTROLS: "[data-vue-product-controls]",
    CATALOG_FILTERS: "[data-vue-catalog-filters]",
    PRODUCTS_LIST: "[data-vue-products-list]",
    COLLECTIONS_LIST: "[data-vue-collections-list]",
    DESKTOP_SORT: "[data-vue-desktop-sort]",
    PROFILE_FORM: "[data-vue-profile-form]",
    FEEDBACK_FORM: "[data-vue-feedback-form]",
    VACANCY_FORM: "[data-vue-vacancy-form]",
    EVENT_FORM: "[data-vue-event-form]",
    FAVORITE_PRODUCTS: "[data-vue-favorite-products]",
    FAVORITE_COLLECTIONS: "[data-vue-favorite-collections]",
    ORDER: "[data-vue-order]",
    ABOUT_EXPANDABLE_BLOCK: "[data-vue-about-expandable-block]",
    ARTICLE_CONTENTS: "[data-vue-article-contents]",
    INTERIOR_RENDERS: "[data-vue-interior-renders]",
    LIGHTBOX: "[data-vue-lightbox]",
    SALE_CATEGORY_GRID: "[data-vue-sale-category]",
};

/** @enum {string} */
export const OTHER_ELEMENTS = {
    INJECT_PRIMARY_CONTENT_ON_SUBMIT_FILTERS_TARGET:
        "[data-inject-primary-html-on-submit-filters]",
    INJECT_MISC_CONTENT_ON_SUBMIT_FILTERS_TARGET:
        "[data-inject-misc-html-on-submit-filters]",
    INJECT_SECONDARY_CONTENT_ON_SUBMIT_FILTERS_TARGET:
        "[data-inject-secondary-html-on-submit-filters]",
    INJECT_SCRIPTS_ON_SUBMIT_FILTERS_TARGET:
        "[data-inject-scripts-on-submit-filters]",
    FILTERS_BACKDROP: "[data-filters-backdrop]",
};

/** @typedef {int|number} Milliseconds */
/** @enum {Milliseconds} */
export const TIMEOUTS = {
    DEBOUNCE: 500,
    NOTIFICATION: 2000,
};

/** @enum {string} */
export const MASKS = {
    PHONE: "Phone",
    CODE: "Code",
    INN: "Inn",
    KPP: "Kpp",
};

/** @enum {int|number} */
export const INPUT_LIMITS = {
    ORDER_COMMENT: 3000,
    FEEDBACK_TEXT: 2000,
    VACANCY_TEXT: 2000,
};

/** @enum {string} */
export const CHECKOUT_TYPES = {
    CONTACT: "contact",
    PURCHASE: "purchase",
};

/** @enum {string} */
export const DELIVERY_TYPES = {
    DELIVERY: "delivery",
    PICKUP: "pickup",
};

/** @enum {string} */
export const PAYMENT_METHODS = {
    WIRE: "wire",
};

/** @enum {string} */
export const PRODUCT_AVAILABILITY_STATUSES = {
    AVAILABLE: "available",
    ON_ORDER: "on_order",
    SOLD_OUT: "sold_out",
    IN_WAY: "in_way",
};

/** @enum {string} */
export const FILTERS_KEYS = {
    PRICE: "price",
    CATEGORIES: "categories",
    FAVORITE_CATEGORIES: "favorite_categories",
    SIZE: "size",
};

/** @enum {string} */
export const SORT_BY_KEYS = {
    NOVELTY: "novelty",
};

/** @enum {string} */
export const SORT_DIRECTION_KEYS = {
    DESC: "desc",
};

/** @enum {string} */
export const INVOICE_STATUSES = {
    ERROR: "error",
    GENERATING: "generating",
    READY: "ready",
};

/** @enum {string} */
export const USER_RATES = {
    LIKE: "like",
    DISLIKE: "dislike",
    NO_RATE: "no_rate",
};

/** @enum {string} */
export const VUEX_GETTERS = {
    USER_NAME: "userName",
    USER_PHONE: "userPhone",
    USER_EMAIL: "userEmail",
    IS_LOGGED_IN: "isLoggedIn",

    CART_ITEMS: "cartItems",
    CART_AMOUNT: "cartAmount",
    CART_PRICE: "cartPrice",
    CART_DISCOUNTED_PRICE: "cartDiscountedPrice",
    CART_DISCOUNT_AMOUNT: "cartDiscountAmount",

    PRODUCTS_FAVORITES: "productsFavorites",
    PRODUCTS_FAVORITES_LOADING: "productsFavoritesLoading",
    COLLECTIONS_FAVORITES: "collectionsFavorites",
    COLLECTIONS_FAVORITES_LOADING: "collectionsFavoritesLoading",

    STATUS_MODIFYING: "statusModifying",
    STATUS_CALCULATING: "statusCalculating",
    STATUS_ORDERING: "statusOrdering",
    STATUS_REDIRECTING: "statusRedirecting",
    STATUS_GENERATING: "statusGenerating",

    ORDER_TOTAL_PRICE: "orderTotalPrice",
    ORDER_TOTAL_PRICE_WITH_DISCOUNT: "orderTotalPriceWithDiscount",
    ORDER_DISCOUNT_AMOUNT: "orderDiscountAmount",

    CURRENTLY_EDITED_LEGAL_ENTITY: "currentlyEditedLegalEntity",
};

/** @enum {string} */
export const VUEX_MUTATIONS = {
    SET_USER_STATE: "setUserState",
    SET_USER_PHONE: "setUserPhone",

    SET_CART_STATE: "setCartState",

    SET_FAVORITE_PRODUCT: "setFavoriteProduct",
    SET_FAVORITE_PRODUCT_LOADING: "setFavoriteProductLoading",
    SET_FAVORITE_COLLECTIONS: "setFavoriteCollection",
    SET_FAVORITE_COLLECTIONS_LOADING: "setFavoriteCollectionLoading",

    SET_STATUS_MODIFYING: "setStatusModifying",
    SET_STATUS_CALCULATING: "setStatusCalculating",
    SET_STATUS_ORDERING: "setStatusOrdering",
    SET_STATUS_REDIRECTING: "setStatusRedirecting",
    SET_STATUS_GENERATING: "setStatusGenerating",

    SET_ORDER_STATE: "setOrderState",
    CLEAR_ORDER_STATE: "clearOrderState",

    SET_CURRENTLY_EDITED_LEGAL_ENTITY: "setCurrentlyEditedLegalEntity",
    SET_RECAPTCHA_INITIALIZED: "setRecaptchaInitialized",
};

/** @enum {string} */
export const VUEX_ACTIONS = {
    GET_CART_DETAILS: "getCartDetails",
    ADD_PRODUCT_TO_CART: "addProductToCart",
    SET_CART_ITEM_QUANTITY: "setCartItemQuantity",
};

export const EVENTS = {
    CHANGE_THUMB_GALLERY_INDEX: "changeThumbGalleryIndex",
    CHANGE_SALE_CATEGORY: "changeSaleCategory",
};
