<template>
    <div
        class="FilePicker"
        :class="{ 'FilePicker--hasAttachedFile': isNotEmpty }"
    >
        <input
            type="file"
            :name="name" :id="id"
            class="FilePicker__input"
            @change="onChange"
            ref="input"
        />
        <label
            :for="id"
            class="FilePicker__label"
            v-text="label"
        ></label>
        <button
            type="button"
            v-if="isNotEmpty"
            class="FilePicker__clear"
            @click="clear" data-uk-close
        ></button>
    </div>
</template>

<script>
    // Because of API naming convention
    /* eslint-disable camelcase */

    import { showDanger } from '@library/uikit/ui-utils';
    import { getBase64FromFile } from '@scripts/helpers';

    export default {
        props: {
            name: {
                type: String,
                required: true,
            },
            id: {
                type: String,
                required: true,
            },
            defaultLabel: {
                type: String,
                required: true,
            },
            defaultAttachedFileName: {
                type: String,
                required: false,
                default: 'Файл',
            },
        },
        data() {
            return {
                label: this.defaultLabel,
                attachedFileName: '',
            };
        },
        computed: {
            isNotEmpty() {
                return this.attachedFileName !== '';
            },
        },
        methods: {
            async onChange() {
                const input = this.$refs.input;

                try {
                    const bytes = await getBase64FromFile(input.files[0]);

                    this.attachedFileName = input.value.split('\\').pop();
                    this.label = this.attachedFileName || this.defaultLabel;

                    const file_name = this.attachedFileName || this.defaultAttachedFileName;
                    this.$emit('change', { file_name, bytes });
                } catch (error) {
                    showDanger({ text: 'Не удалось прикрепить резюме' });
                    this.clear();
                }
            },

            clear() {
                const input = this.$refs.input;

                input.value = '';
                this.attachedFileName = '';
                this.label = this.defaultLabel;

                this.$emit('clear');
            },
        },
    };
</script>
