'use strict'

export const or = (...fns) => (...args) => fns.some((f) => f(...args))
export const and = (...fns) => (...args) => fns.every((f) => f(...args))
export const not = f => (...args) => !f(...args)

export const Logic = {
	or,
	and,
	not,
}
