// Because of API naming convention
/* eslint-disable camelcase */

import {
    getCartDetails,
    addProductToCart,
    setCartItemQuantity,
} from '../scripts/api-methods';
import {
    VUEX_MUTATIONS,
} from '../scripts/constants';

async function cartAction({ commit }, { method, payload = null }) {
    commit(VUEX_MUTATIONS.SET_STATUS_MODIFYING, true);

    try {
        const response = await method(payload);

        if (response) {
            commit(VUEX_MUTATIONS.SET_CART_STATE, response);
        }

        return response;
    } catch (error) {
        throw error;
    } finally {
        commit(VUEX_MUTATIONS.SET_STATUS_MODIFYING, false);
    }
}

export default {
    async getCartDetails({ commit }) {
        try {
            return await cartAction({ commit }, {
                method: getCartDetails,
            });
        } catch (error) {
            throw error;
        }
    },

    async addProductToCart({ commit, state }, { product_id, quantity }) {
        try {
            return await cartAction({ commit }, {
                method: addProductToCart,
                payload: { product_id, quantity },
            });
        } catch (error) {
            throw error;
        }
    },

    async setCartItemQuantity({ commit, state }, { item_id, quantity }) {
        try {
            return await cartAction({ commit }, {
                method: setCartItemQuantity,
                payload: { item_id, quantity },
            });
        } catch (error) {
            throw error;
        }
    },
};
