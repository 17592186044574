// Because of API naming convention
/* eslint-disable camelcase */

import Vue from 'vue';
import get from 'lodash-es/get';
import { mask } from 'vue-the-mask';
import VueAutosize from 'vue-autosize';

import * as Sentry from '@sentry/browser';

import { showDanger, showSuccess, hideModal, showModal } from '@library/uikit/ui-utils';
import { PhoneMask } from '@library/scripts/masks';
import errorHandler from '@library/scripts/vue/mixins/errorHandler';

import { getFromBinding } from '@scripts/helpers';
import { applyVacancy } from '@scripts/api-methods';
import { recaptchaLoader } from '@scripts/mixins';
import EventBus from '@scripts/event-bus';
import {
    GLOBAL,
    GLOBAL_EVENTS,
    MODALS,
    MASKS,
    INPUT_LIMITS,
    VUEX_GETTERS,
} from '@scripts/constants';

import UIkit from '../../vendor/uikit/uikit';
import store from '../../store';

import FilePicker from '../file-picker/file-picker.vue';

function resumeSchema({
    file_name = '',
    bytes = null
} = {}) {
    return { file_name, bytes };
}

function dataSchema() {
    return {
        name: get(store.getters, VUEX_GETTERS.USER_NAME, ''),
        phone: get(store.getters, VUEX_GETTERS.USER_PHONE, ''),
        email: get(store.getters, VUEX_GETTERS.USER_EMAIL, ''),
        resume: resumeSchema(),
        message: '',
        token: '',
    };
}

Vue.use(VueAutosize);

// use in DOM interactions
export function handleToggleVacancyFormModal() {
    document.querySelectorAll('[data-toggle-vacancy-form-modal]').forEach(item => {
        if (item) {
            item.addEventListener('click', event => {
                EventBus.$emit(GLOBAL_EVENTS.SET_VACANCY, {
                    title: get(event, 'currentTarget.dataset.title', ''),
                    id: get(event, 'currentTarget.dataset.id', null),
                });
                showModal(MODALS.VACANCY);
            });
        }
    });
}

export default (el, name) => new Vue({
    el,
    name,
    store,
    components: {
        FilePicker,
    },
    directives: { mask },
    mixins: [
        recaptchaLoader,
        errorHandler({
            fields: {
                name: {
                    target: 'data.name',
                    handler(v) {
                        return v && v.toString().length > 0;
                    },
                    message() {
                        return 'Заполните поле';
                    },
                },
                phone: {
                    target: 'data.phone',
                    handler(v) {
                        return v && v.toString().length > 0 && PhoneMask.testFormatted(v);
                    },
                    message(v) {
                        if (v.toString().length < 1) return 'Заполните поле';
                        if (!PhoneMask.testFormatted(v)) return 'Заполните поле полностью';
                        return 'Неверное значение';
                    },
                },
                message: {
                    target: 'data.message',
                    handler(v) {
                        return v.toString().length >= 3;
                    },
                    message(v) {
                        if (v.toString().length < 1) return 'Заполните поле';
                        if (v.toString().length < 3) return 'Нужно минимум 3 символа';
                    },
                },
            },
        }),
    ],
    data() {
        return {
            title: '',
            vacancyId: null,
            data: dataSchema(),
            resume: resumeSchema(),
            loading: false,
            INPUT_LIMITS,
            MASKS,
        };
    },
    beforeMount() {
        EventBus.$on(GLOBAL_EVENTS.SET_VACANCY, event => {
            this.title = get(event, 'title', '');
            this.vacancyId = get(event, 'id', null);
        });
    },
    mounted () {
        UIkit.util.on(MODALS.VACANCY, 'show', () => this.loadRecaptcha());
        UIkit.util.on(MODALS.VACANCY, 'hide', () => this.hideRecaptchaBadge());
    },
    watch: {
        data: {
            deep: true,
            handler: 'clearInvalidMessages',
        },
    },
    computed: {
        isSubmitting() {
            return this.loading;
        },

        isDisabledSubmitForm() {
            return this.isSubmitting;
        },
    },
    methods: {
        async submit() {
            if (this.isDisabledSubmitForm) return;

            this.clearInvalidMessages();
            if (this.isFailedForm(['name', 'phone', 'message'])) return;

            this.loading = true;
            if (getFromBinding(GLOBAL.RECAPTCHA_SITE_KEY)) {
                try {
                    this.data.token = await this.$recaptcha('vacancy');
                } catch (error) {
                    showDanger({ text: 'Произошла ошибка reCAPTCHA' });
                    this.loading = false;

                    Sentry.captureException(error);
                    console.error(error);
                    return;
                }
            }

            function nullable(field) {
                return field.length > 0 ? field : null;
            }

            try {
                await applyVacancy({
                    ...this.data,
                    vacancy_id: this.vacancyId,
                    email: nullable(this.data.email),
                    resume: this.data.resume.bytes ? {
                        file_name: this.data.resume.file_name,
                        bytes: this.data.resume.bytes.split(',')[1],
                    } : null,
                });
                showSuccess({ text: 'Спасибо что откликнулись на нашу вакансию' });
                this.close();

                setTimeout(() => {
                    this.clearForm();
                }, 1000); // 1s
            } catch (error) {
                this.handleError(error);
            } finally {
                this.loading = false;
            }
        },

        clearForm() {
            this.data = dataSchema();
            this.$refs.resume.clear();
        },

        clearResume() {
            this.data.resume = resumeSchema();
        },

        close() {
            hideModal(MODALS.VACANCY);
        },

        isFailedForm(fields) {
            let result = false;
            fields.forEach(item => {
                if (!this.isValidField(item)) result = true;
            });
            return result;
        },

        isNotEmpty(value) {
            return value !== null && value !== '';
        },

        onChangeResume(event) {
            this.data.resume = resumeSchema({ ...event });
        },

        onInputPhone(event) {
            if (/^[8]/.test(event.currentTarget.value)) {
                event.currentTarget.value = event.currentTarget.value.replace(/^[8]/g, '+7');
            }
            this.data.phone = event.currentTarget.value;
        },

        onBeforeShow() {
            this.showRecaptchaBadge();
        },

        onShown() {
            // TODO: fix scrollbar that appears near the text field
            //  after successfully sending a message and reopening the modal popup
        },

        onHide() {
            this.clearInvalidMessages();
            this.hideRecaptchaBadge();
        },

        onError(text) {
            showDanger({ text });
        },
    }
});
