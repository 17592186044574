import noUiSlider from 'nouislider';
import { Is } from '@library/scripts/is';
import { mod } from '@library/scripts/math';
import { addToFavorites,
    removeFromFavorites,
    addToFavoriteCollections,
    removeFromFavoriteCollections,
} from "@scripts/api-methods";
import { SLIDER_EVENTS, CART } from './constants';
import { loadRecaptcha } from './recaptcha-loader';

export const amountSetter = {
    methods: {
        setItemQuantity (inputValue, cartItem = this, target = null) {
            let value = Number(inputValue);
            const oldQuantity = cartItem.quantity;

            if (value >= CART.MAX_ITEM_QUANTITY) value = CART.MAX_ITEM_QUANTITY;
            if (value < 0) value = 0;

            cartItem.quantity = value;
            const modifier = 1;
            const residue = modifier - mod(cartItem.quantity, modifier);

            if (residue !== modifier) {
                cartItem.quantity = cartItem.quantity + residue
                    ? cartItem.quantity - residue
                    : cartItem.quantity + residue;
            }

            if (oldQuantity !== cartItem.quantity && Is.function(this.onAmountChange)) {
                this.onAmountChange(cartItem);
            } else if (Is.function(this.syncQuantity)) {
                this.syncQuantity();
            }

            if (cartItem.quantity === 0 && Is.function(this.onDelete)) {
                this.onDelete(cartItem);
            }

            if (target !== null && target instanceof Element) {
                target.value = cartItem.quantity;
            }
        },

        onProductQuantityInputChange (target, product, unitsCoef = 1) {
            let quantity = Math.round(target.value / unitsCoef);
            if (quantity > 0) {
                quantity = Math.min(quantity, CART.MAX_ITEM_QUANTITY);
                this.setItemQuantity(quantity, product, target);
            } else {
                // If you enter and incorrect value in cart counter then it should be casted to one
                quantity = 'cart' in target.dataset ? 1 : 0;
                this.setItemQuantity(quantity, product, target);
            }
            target.value = quantity * unitsCoef;
        },
    },
};

export const priceSlider = {
    computed: {
        displayedMinPrice () {
            return 'от ' + this.formatPrice(this.priceSlider.minPrice, true);
        },

        displayedMaxPrice () {
            return 'до ' + this.formatPrice(this.priceSlider.maxPrice, true);
        },
    },
    methods: {
        updatePrice (event, isMaxPrice = false) {
            let sliderData = this.priceSlider;
            let value = event.target.value;

            if (value === '') return;

            let price = this.priceToInt(value) || 0;

            if (price === 0) {
                if (!isMaxPrice) {
                    sliderData['minPrice'] = sliderData.options['totalMin']; // Set to minimum
                } // Still previous value (maxPrice)
            } else {
                if (isMaxPrice) {
                    sliderData['maxPrice'] = Math.min(Math.max(price, sliderData['minPrice']), sliderData.options['totalMax']);
                } else {
                    sliderData['minPrice'] = Math.max(Math.min(price, sliderData['maxPrice']), sliderData.options['totalMin']);
                }
            }

            event.target.value = ''; // Show formatted placeholder instead
            this.$refs.slider.noUiSlider.set([sliderData['minPrice'], sliderData['maxPrice']]);
        },

        formatPrice (price, addRoubleSign) {
            return new Intl.NumberFormat('ru-RU').format(price) + (addRoubleSign ? ' ₽' : '');
        },

        priceToInt (price) {
            return Math.abs(parseInt(price.replace(/\s/g, '')));
        },

        formatValue (event) {
            let value = event.target.value;

            if (value === '') return;

            let price = this.priceToInt(value) || 0;
            event.target.value = this.formatPrice(price);
        },

        initSlider (element, data) {
            // Slider dataset can be absent
            if (!element) return;

            let options = data.options = {
                totalMin: parseInt(element.dataset.totalMin),
                currentMin: parseInt(element.dataset.currentMin),
                totalMax: parseInt(element.dataset.totalMax),
                currentMax: parseInt(element.dataset.currentMax),
                step: parseInt(element.dataset.step),
            };

            noUiSlider.create(element, {
                start: [
                    options.currentMin,
                    options.currentMax,
                ],
                connect: true,
                step: options.step,
                range: {
                    'min': options.totalMin,
                    'max': options.totalMax,
                },
            });

            data.minPrice = options.currentMin;
            data.maxPrice = options.currentMax;

            element.noUiSlider.on('slide', (values, handle) => {
                data[handle ? 'maxPrice' : 'minPrice'] = parseInt(values[handle]);
            });

            element.noUiSlider.on('slide', () => {
                if (typeof this.$emit === 'function') {
                    this.$emit(SLIDER_EVENTS.SLIDE);
                }
            });
        },

        updateSlider (element, data) {
            if (!element) return;

            let options = data.options = {
                totalMin: parseInt(element.dataset.totalMin),
                currentMin: parseInt(element.dataset.currentMin),
                totalMax: parseInt(element.dataset.totalMax),
                currentMax: parseInt(element.dataset.currentMax),
                step: parseInt(element.dataset.step),
            };

            element.noUiSlider.updateOptions({
                step: options.step,
                range: {
                    'min': options.totalMin,
                    'max': options.totalMax,
                },
            }, true);

            data.minPrice = options.currentMin;
            data.maxPrice = options.currentMax;
        },

        resetSlider (element, data) {
            if (!element) return;

            data.minPrice = data.options.totalMin;
            data.maxPrice = data.options.totalMax;

            element.noUiSlider.reset();
        },
    },
};

export const recaptchaLoader = {
    methods: {
        loadRecaptcha () {
            loadRecaptcha()
                .then(() => {
                    this.showRecaptchaBadge();
                })
                .catch(console.warn);
        },
    },
};

export const productsFavoritesMixin = {
    data() {
        return {
            favoritesProduct: {},
            favoritesProductLoading: {},
        };
    },
    methods: {
        syncFavorites() {
            this.favoritesProduct = Object.keys(this.productsFavorites).reduce((map, item) => {
                map[item] = this.productsFavorites[item];
                return map;
            }, {});
        },

        syncFavoritesLoading() {
            this.favoritesProductLoading = Object.keys(this.productsFavoritesLoading).reduce((map, item) => {
                map[item] = this.productsFavoritesLoading[item];
                return map;
            }, {});
        },

        async toggleFavorite(event, productId) {
            if (event !== undefined) {
                event.stopPropagation();
            }

            try {
                this.setFavoriteProductLoading({ productId, flag: true });
                const payload = {
                    product_id: +productId,
                };
                const isFavorite = this.isFavorite(productId);

                await (isFavorite ? removeFromFavorites(payload) : addToFavorites(payload));
                this.setFavoriteProduct({ productId, flag: !isFavorite })
            } catch (error) {
                this.handleError(error);
            } finally {
                this.setFavoriteProductLoading({ productId, flag: false });
            }
        },

        isTogglingFavorite(productId) {
            return this.favoritesProductLoading[productId] ?? false;
        },

        isFavorite(productId) {
            return this.favoritesProduct[productId] ?? false;
        },

        isDisabledToggleFavorite(productId) {
            return this.isTogglingFavorite(productId);
        },
    },
};

export const collectionsFavoritesMixin = {
    data() {
        return {
            favoritesCollections: {},
            favoritesCollectionsLoading: {},
        };
    },
    methods: {
        syncCollectionFavorites() {
            this.favoritesCollections = Object.keys(this.collectionsFavorites).reduce((map, item) => {
                map[item] = this.collectionsFavorites[item];
                return map;
            }, {});
        },

        syncCollectionFavoritesLoading() {
            this.favoritesCollectionsLoading = Object.keys(this.collectionsFavoritesLoading).reduce((map, item) => {
                map[item] = this.collectionsFavoritesLoading[item];
                return map;
            }, {});
        },

        async toggleCollectionFavorite(event, collectionId) {
            event.stopPropagation();
            try {
                this.setFavoriteCollectionLoading({ collectionId, flag: true });
                const payload = {
                    collection_id: +collectionId,
                };
                const isFavorite = this.isCollectionFavorite(collectionId);

                await (isFavorite ? removeFromFavoriteCollections(payload) : addToFavoriteCollections(payload));
                this.setFavoriteCollection({ collectionId, flag: !isFavorite });
            } catch (error) {
                this.handleError(error);
            } finally {
                this.setFavoriteCollectionLoading({ collectionId, flag: false });
            }
        },

        isTogglingCollectionFavorite(collectionId) {
            return this.favoritesCollectionsLoading[collectionId] ?? false;
        },

        isCollectionFavorite(collectionId) {
            return this.favoritesCollections[collectionId] ?? false;
        },

        isDisabledToggleCollectionFavorite(collectionId) {
            return this.isTogglingCollectionFavorite(collectionId);
        },
    },
};
