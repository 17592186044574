import Vue from 'vue';
import debounce from 'lodash-es/debounce';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import errorHandler from '@library/scripts/vue/mixins/errorHandler';
import { showDanger } from '@library/uikit/ui-utils';
import { amountSetter, productsFavoritesMixin } from '@scripts/mixins';
import { removeChildClasses } from '@scripts/helpers';
import get from 'lodash-es/get';
import {
    CART,
    TIMEOUTS,
    VUEX_GETTERS,
    VUEX_MUTATIONS,
    VUEX_ACTIONS,
} from '@scripts/constants';
import {
    apiProductRate,
    apiRetractProductRate,
} from '@scripts/api-methods';
import store from '../../store';

export default (el, name) => new Vue({
    el,
    name,
    store,
    mixins: [
        errorHandler(),
        amountSetter,
        productsFavoritesMixin,
    ],
    data() {
        return {
            quantityMap: {},
            unitsMap: {},
            reactionMap: {},
            loading: {},
            CART,
        };
    },
    beforeCreate() {
        // clear initial counter state after Vue is loaded
        removeChildClasses(el, ['AddToCart--showCounter', 'UnitsChanger__unit--active']);
    },
    beforeMount() {
        this.sync();
        this.syncFavorites();
        this.syncFavoritesLoading();
        store.subscribe(({ type }) => {
            if (type === VUEX_MUTATIONS.SET_CART_STATE) {
                this.sync();
            }

            if (type === VUEX_MUTATIONS.SET_FAVORITE_PRODUCT) {
                this.syncFavorites();
            }

            if (type === VUEX_MUTATIONS.SET_FAVORITE_PRODUCT_LOADING) {
                this.syncFavoritesLoading();
            }
        });
    },
    mounted() {
        Object.keys(this.$refs).forEach(element => {
            const productId = this.$refs[element].dataset.vueProductId;

            // reactionMap
            if (this.$refs[element].dataset.vueProductRate !== undefined) {
                const rate = this.$refs[element].dataset.vueProductRate;

                this.reactionMap = {
                    ...this.reactionMap,
                    [productId]: rate,
                };
            }

            // favoritesMap
            if (this.$refs[element].dataset.vueProductIsFavorite !== undefined) {
                const isFavorite = !!this.$refs[element].dataset.vueProductIsFavorite;

                this.setFavoriteProduct({ productId, flag: isFavorite });
            }
        });
    },
    computed: {
        ...mapGetters([
            VUEX_GETTERS.CART_ITEMS,
            VUEX_GETTERS.PRODUCTS_FAVORITES,
            VUEX_GETTERS.PRODUCTS_FAVORITES_LOADING,
        ]),
    },
    methods: {
        ...mapMutations([
            VUEX_MUTATIONS.SET_FAVORITE_PRODUCT,
            VUEX_MUTATIONS.SET_FAVORITE_PRODUCT_LOADING,
        ]),

        ...mapActions([
            VUEX_ACTIONS.ADD_PRODUCT_TO_CART,
            VUEX_ACTIONS.SET_CART_ITEM_QUANTITY,
        ]),

        sync() {
            this.quantityMap = this.cartItems.reduce((map, item) => {
                map[item.product.id] = {
                    id: item.id,
                    quantity: item.quantity,
                };
                return map;
            }, {});
        },

        async addToCart(productId) {
            this.$set(this.loading, productId, true);
            try {
                await this.addProductToCart({
                    product_id: productId,
                    quantity: 1,
                });
            } catch (error) {
                this.handleError(error);
            } finally {
                this.$set(this.loading, productId, false);
            }
        },

        // Units

        getUnits(productId, isFormatted = true) {
            let unit = get(this.unitsMap[productId], 'units', '')

            if (isFormatted) {
                if (unit === 'м2') unit = 'м²'
                if (unit === 'м3') unit = 'м³'
            }

            return unit;
        },

        getUnitsCoef(productId, defaultValue = 1) {
            return get(this.unitsMap[productId], 'unitsCoef', defaultValue);
        },

        formatPriceBasedOnUnits(price, productId, units = null, unitsCoef = null) {
            if (units !== null && unitsCoef !== null && this.unitsMap[productId] === undefined) {
                this.changeUnits(productId, units, unitsCoef);
            }

            const coef = this.getUnitsCoef(productId);
            return this.formatRoubles(price / coef);
        },

        formatBalanceBasedOnUnits(balance, productId, units = null, unitsCoef = null) {
            if (units !== null && unitsCoef !== null && this.unitsMap[productId] === undefined) {
                this.changeUnits(productId, units, unitsCoef);
            }
            const coef = this.getUnitsCoef(productId);
            return `${Math.round(balance * coef * 10) / 10} ${this.getUnits(
                productId
            )}`;
        },

        getCounterCoef(productId, quantity) {
            return parseFloat((quantity * this.getUnitsCoef(productId)).toFixed(3));
        },

        changeUnits(productId, units, unitsCoef = 1) {
            this.unitsMap = {
                ...this.unitsMap,
                [productId]: {
                    units,
                    unitsCoef
                },
            };
        },

        // end Units

        isProductInCart(productId) {
            return productId in this.quantityMap && this.quantityMap[productId].quantity > 0;
        },

        isDisabledAddToCartButton(productId) {
            return this.isProductInCart(productId) || this.loading[productId];
        },

        onAmountChange: debounce(async function(cartItem) {
            try {
                await this.setCartItemQuantity({
                    item_id: cartItem.id,
                    quantity: cartItem.quantity,
                });
            } catch (error) {
                this.handleError(error);
            }
        }, TIMEOUTS.DEBOUNCE),

        onError(text) {
            showDanger({ text });
        },

        /**
         *
         * @param {Event} event
         * @param {'like' | 'dislike'} rate
         * @param {number} productID
         * @return void
         */
        onRateClick(event, rate, productID) {
            event.preventDefault();
            event.stopPropagation();

            // If we click on the same rate, e.g. if we already liked product and click on like again
            // So we want retract vote
            if (rate === this.reactionMap[productID]) {
                this.reactionMap = {
                    ...this.reactionMap,
                    [productID]: undefined,
                };

                /**
                 * @type {ProductRetractRateRequest}
                 */
                const payload = {
                    product_id: productID,
                };

                apiRetractProductRate(payload)
                    .then(() => {})
                    .catch((err) => {
                        this.handleError(err);
                        this.reactionMap = {
                            ...this.reactionMap,
                            [productID]: rate,
                        };
                    });
            } else {
                this.reactionMap = {
                    ...this.reactionMap,
                    [productID]: rate,
                };

                /**
                 * @type {ProductRateRequest}
                 */
                const payload = {
                    product_id: productID,
                    type: rate,
                };

                apiProductRate(payload)
                    .then(() => {})
                    .catch((err) => {
                        this.handleError(err);
                        this.reactionMap = {
                            ...this.reactionMap,
                            [productID]: undefined,
                        };
                    });
            }
        },
    },
});
