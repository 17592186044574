export default {
    userName: state => state.user.name,
    userPhone: state => state.user.phone,
    userEmail: state => state.user.email,
    isLoggedIn: state => state.user.loggedIn,

    cartItems: state => state.cart.items,
    cartAmount: state => state.cart.items_amount,
    cartPrice: state => state.cart.price,
    cartDiscountedPrice: state => state.cart.price_discounted,
    cartDiscountAmount: state => state.cart.discount,

    productsFavorites: state => state.favorites.products,
    collectionsFavorites: state => state.favorites.collections,
    productsFavoritesLoading: state => state.favorites.productsLoading,
    collectionsFavoritesLoading: state => state.favorites.collectionsLoading,

    statusModifying: state => state.status.modifying,
    statusCalculating: state => state.status.calculating,
    statusOrdering: state => state.status.ordering,
    statusRedirecting: state => state.status.redirecting,
    statusGenerating: state => state.status.generating,

    orderTotalPrice: state => state.order.price_total,
    orderTotalPriceWithDiscount: state => state.order.price_total_with_discount,
    orderDiscountAmount: state => state.order.discount,

    currentlyEditedLegalEntity: state => state.miscellaneous.currentlyEditedLegalEntity,
    isRecaptchaInitialized: state => state.miscellaneous.isRecaptchaInitialized,
};
