'use strict'

export class Log {
	static requestError(name, payload = null, error = null, extra = null) {
		let args = [`[${name}]`]
		if (payload !== null) args = [...args, '\nPayload:', payload]
		if (error !== null) args = [...args, '\nError:', error]
		if (extra !== null) args = [...args, `\n${extra}`]
		console.error(...args)
	}
}
