export function paginatedItemsSchema({
    items = [],
    totalItems = 0,
    currentPage = 1,
    itemsPerPage = 4,
} = {}) {
    return {
        items,
        totalItems,
        currentPage,
        itemsPerPage,
    };
}
