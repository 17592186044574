'use strict'

import UIkit from 'uikit/dist/js/uikit-core'
import { NOTIFICATION_TYPES, DefaultTimeout } from '../scripts/constants'
import Notification from 'uikit/dist/js/components/notification'
import { LibraryLocalization } from '../scripts/l10n'

UIkit.component('notification', Notification)

export function showNotification({
	text = '',
	title = LibraryLocalization.get('NOTIFICATION_TITLES.DANGER'),
	status = NOTIFICATION_TYPES.DANGER,
	timeout = DefaultTimeout.Notifications
}) {
	const header = title || ''
	const message = `
		<div class='uk-flex uk-flex-middle feipNotification feipNotification_${status}'>
			<div class='feipNotification-icon_${status} feipNotification-icon'></div>
			<div class='uk-margin-left feipNotification-content'>
				<h6 class='feipNotification-header'>${header}</h6>
				<p class='feipNotification-innerText uk-display-inline-block'>${text}</p>
			</div>
		</div>`
	UIkit.notification({ message, pos: 'top-right', timeout })
}

export function showDanger({ title = LibraryLocalization.get('NOTIFICATION_TITLES.DANGER'), text = '', timeout }) {
	showNotification({ title, text, timeout })
}

export function showWarning({ title = LibraryLocalization.get('NOTIFICATION_TITLES.WARNING'), text = '', timeout }) {
	showNotification({ status: NOTIFICATION_TYPES.WARNING, title, text, timeout })
}

export function showSuccess({ title = LibraryLocalization.get('NOTIFICATION_TITLES.SUCCESS'), text = '', timeout }) {
	showNotification({ status: NOTIFICATION_TYPES.SUCCESS, title, text, timeout })
}

export function showInfo({ title = LibraryLocalization.get('NOTIFICATION_TITLES.PRIMARY'), text = '', timeout }) {
	showNotification({ status: NOTIFICATION_TYPES.PRIMARY, title, text, timeout })
}

/** @typedef {string} DOMQuery */

/**
 * @param {DOMQuery} query
 * @return {Promise}
 */
export function hideModal(query) {
	return UIkit.modal(query).hide()
}
/**
 * @param {DOMQuery} query
 * @return {Promise}
 */
export function showModal(query) {
	return UIkit.modal(query).show()
}
