import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';

Vue.use(Vuex);

const Store = new Vuex.Store({
    state: {
        user: {
            name: '',
            phone: '',
            email: '',
            loggedIn: false,
        },
        cart: {
            // field names purposely match protocol
            items: [],
            items_amount: 0,
            price: 0,
            price_discounted: 0,
            discount: 0,
        },
        favorites: {
            products: {},
            collections: {},
            productsLoading: {},
            collectionsLoading: {},
        },
        status: {
            modifying: false, // cart
            calculating: false, // purchase
            ordering: false,
            redirecting: false,
            generating: false, // invoice
        },
        order: {
            price_total: 0,
            price_total_with_discount: 0,
            discount: 0,
        },
        miscellaneous: {
            currentlyEditedLegalEntity: null,
            isRecaptchaInitialized: false,
        },
    },
    getters,
    mutations,
    actions,
});

export default Store;
