import Vue from 'vue';
import get from 'lodash-es/get';

import { currencyFormatter, displayCurrency } from '@library/scripts/currency';
import { INPUT_MASKS } from '@library/scripts/constants';

import { formatCurrency } from './helpers';

/** @enum {string} */
const Masks = {
    Phone: INPUT_MASKS.PHONE_MASK,
    Code: INPUT_MASKS.CODE_MASK,
    Inn: INPUT_MASKS.INN_MASK,
    Kpp: INPUT_MASKS.KPP_MASK,
};

/** @enum {string} */
const KnownCurrencyFormat = {
    RUB: 'RUB',
};

const initGlobals = () => {
    // TODO: probably need a formatter for unit price
    Vue.mixin({
        methods: {
            /**
             * @param {number} kopecks
             * @param {Object} options
             * @return {string}
             */
            formatRoubles: (kopecks, { placeholderForFreeAmount = null, removeTrailingZeros = true } = {}) => {
                if (kopecks === 0 && placeholderForFreeAmount) return placeholderForFreeAmount;
                return displayCurrency(KnownCurrencyFormat.RUB, kopecks / 100, 2, '&thinsp;', removeTrailingZeros);
            },

            /**
             * @param {number} kopecks
             * @param {Object} options
             * @return {string}
             */
            formatRoublesWithoutSign: (kopecks, { placeholderForFreeAmount = null, removeTrailingZeros = true } = {}) => {
                if (kopecks === 0 && placeholderForFreeAmount) return placeholderForFreeAmount;
                return formatCurrency(kopecks, { removeTrailingZeros });
            },

            formatUnits(unit) {
                return unit.replace('м2', 'м&sup2;').replace('м3', 'м&sup3;');
            },

            /**
             * @param {number} kopecks
             * @param {string} measureUnit
             * @param {Object} options
             * @return {string}
             */
            formatRoublesWithMeasureUnit: (kopecks, measureUnit, {
                placeholderForFreeAmount = null,
                removeTrailingZeros = true
            } = {}) => {
                if (kopecks === 0 && placeholderForFreeAmount) return placeholderForFreeAmount;
                const units = measureUnit.replace('м2', 'м&sup2;').replace('м3', 'м&sup3;');
                return `${formatCurrency(kopecks, { removeTrailingZeros })}&nbsp;₽${measureUnit ? ` / ${units}` : ''}`;
            },

            /**
             * @param {number} percents
             * @return {string}
             */
            formatPercents: percents => `${currencyFormatter(percents)}%`,

            /**
             * @param {string} value
             * @return {string}
             */
            formatRussianPhone: value => /^[8]/.test(value) ? value.replace(/^[8]/g, '+7') : value,

            /**
             * @param {Masks} key
             * @return {string}
             */
            getMask: key => get(Masks, key, `Invalid mask key ${key}`),

            showRecaptchaBadge: () => {
                const badge = document.querySelector('.grecaptcha-badge');
                if (badge) badge.style.visibility = 'visible';
            },

            hideRecaptchaBadge: () => {
                const badge = document.querySelector('.grecaptcha-badge');
                if (badge) badge.style.visibility = 'hidden';
            },
        },
    });
};

export default initGlobals;
