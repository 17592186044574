import Vue from 'vue';

import store from '../store';
import { getFromBinding } from './helpers';
import { GLOBAL, VUEX_MUTATIONS } from './constants';

export const loadRecaptcha = async () => {
    return new Promise((resolve, reject) => {
        if (store.getters.isRecaptchaInitialized) {
            reject(new Error('Recaptcha already loaded'));
            return;
        }

        const recaptchaSiteKey = getFromBinding(GLOBAL.RECAPTCHA_SITE_KEY);

        if (recaptchaSiteKey) {
            import('./recaptcha').then(({ VueReCaptcha }) => {
                Vue.use(VueReCaptcha, { siteKey: recaptchaSiteKey });
                store.commit(VUEX_MUTATIONS.SET_RECAPTCHA_INITIALIZED, true);
                resolve();
            });
        } else {
            Vue.prototype.$recaptcha = (action) => {
                throw new Error(`ReCaptcha is not initialized, cannot fetch token for '${action}' action`);
            };
            reject(new Error('ReCaptcha is not initialized'));
        }
    });
};
