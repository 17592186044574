// Because of API naming convention
/* eslint-disable camelcase */

import { v4 as uuidv4 } from 'uuid';
import ApiClient from '@library/scripts/api/client';
import { ROUTES } from './constants';

/**
 * @param {AuthRequestCodePayload} payload
 * @return {Promise<AuthRequestCodeResponse>}
 */
export function authRequestCode({ phone, token }) {
    return ApiClient.post(ROUTES.API.AUTH_REQUEST_CODE, { phone, token });
}

/**
 * @param {LoginPayload} payload
 * @return {Promise<LoginResponse>}
 * */
export function login({ phone, code }) {
    return ApiClient.post(ROUTES.API.LOGIN, { phone, code });
}

/**
 * @return {Promise<{}>}
 * */
export function logout() {
    return ApiClient.post(ROUTES.API.LOGOUT);
}

/**
 * @param {UpdateProfilePayload} payload
 * @return {Promise<UpdateProfileResponse>}
 */
export function updateProfile(payload) {
    return ApiClient.post(ROUTES.API.UPDATE_PROFILE, payload);
}

/**
 * @param {AuthRequestCodePayload} payload
 * @return {Promise<AuthRequestCodeResponse>}
 */
export function changePhoneRequestCode({ phone, token }) {
    return ApiClient.post(ROUTES.API.CHANGE_PHONE_REQUEST_CODE, { new_phone: phone, token });
}

/**
 * @param {LoginPayload} payload
 * @return {Promise<{}>}
 * */
export function changePhoneComplete({ phone, code }) {
    return ApiClient.post(ROUTES.API.CHANGE_PHONE_COMPLETE, { new_phone: phone, code });
}

/**
 * @param {EditLegalEntityPayload} payload
 * @return {Promise<GetEntitiesListResponse>}
 * */
export function editLegalEntity(payload) {
    return ApiClient.post(ROUTES.API.EDIT_LEGAL_ENTITY, payload);
}

/**
 * @param {DeleteLegalEntityPayload} payload
 * @return {Promise<GetEntitiesListResponse>}
 * */
export function deleteLegalEntity(payload) {
    return ApiClient.post(ROUTES.API.DELETE_LEGAL_ENTITY, payload);
}

/**
 * @param {GetEntitiesListPayload} payload
 * @return {Promise<GetEntitiesListResponse>}
 * */
export function getEntitiesList(payload) {
    return ApiClient.get(ROUTES.API.GET_ENTITIES_LIST, payload);
}

/**
 * @param {AddToFavoritesPayload} payload
 * @return {Promise<GetFavoritesListResponse>}
 * */
export function addToFavorites(payload) {
    return ApiClient.post(ROUTES.API.ADD_TO_FAVORITES, payload);
}

/**
 * @param {RemoveFromFavoritesPayload} payload
 * @return {Promise<GetFavoritesListResponse>}
 * */
export function removeFromFavorites(payload) {
    return ApiClient.post(ROUTES.API.REMOVE_FROM_FAVORITES, payload);
}

/**
 * @param {GetFavoritesListPayload} payload
 * @return {Promise<GetFavoritesListResponse>}
 * */
export function getFavoritesList(payload) {
    return ApiClient.get(ROUTES.API.GET_FAVORITES_LIST, payload);
}

/**
 * @param {AddToFavoriteCollectionsPayload} payload
 * @return {Promise<GetFavoriteCollectionsListResponse>}
 * */
export function addToFavoriteCollections(payload) {
    return ApiClient.post(ROUTES.API.ADD_TO_FAVORITE_COLLECTIONS, payload);
}

/**
 * @param {RemoveFromFavoriteCollectionsPayload} payload
 * @return {Promise<GetFavoriteCollectionsListResponse>}
 * */
export function removeFromFavoriteCollections(payload) {
    return ApiClient.post(ROUTES.API.REMOVE_FROM_FAVORITE_COLLECTIONS, payload);
}

/**
 * @param {GetFavoriteCollectionsListPayload} payload
 * @return {Promise<GetFavoriteCollectionsListResponse>}
 * */
export function getFavoriteCollectionsList(payload) {
    return ApiClient.get(ROUTES.API.GET_FAVORITE_COLLECTIONS_LIST, payload);
}

/**
 * @return {Promise<CartDetailsResponse>}
 * */
export function getCartDetails() {
    // add unique string to prevent cache response
    return ApiClient.get(ROUTES.API.GET_CART_DETAILS, { uuid: uuidv4() });
}

/**
 * @param {AddProductToCartPayload} payload
 * @return {Promise<CartDetailsResponse>}
 * */
export function addProductToCart({ product_id, quantity }) {
    return ApiClient.post(ROUTES.API.ADD_PRODUCT_TO_CART, { product_id, quantity });
}

/**
 * @param {SetCartItemQuantityPayload} payload
 * @return {Promise<CartDetailsResponse>}
 * */
export function setCartItemQuantity({ item_id, quantity }) {
    return ApiClient.post(ROUTES.API.SET_CART_ITEM_QUANTITY, { item_id, quantity });
}

/**
 * @param {SelectCityPayload} payload
 * @return {Promise<{}>}
 * */
export function selectCity({ city_id }) {
    return ApiClient.post(ROUTES.API.SELECT_CITY, { city_id });
}

/**
 * @param {ApplyEventPayload} payload
 * @return {Promise<{}>}
 * */
export function applyEvent(payload) {
    return ApiClient.post(ROUTES.API.APPLY_EVENT, payload);
}

/**
 * @param {GiveFeedbackPayload} payload
 * @return {Promise<{}>}
 * */
export function giveFeedback(payload) {
    return ApiClient.post(ROUTES.API.GIVE_FEEDBACK, payload);
}

/**
 * @param {ApplyVacancyPayload} payload
 * @return {Promise<{}>}
 * */
export function applyVacancy(payload) {
    return ApiClient.post(ROUTES.API.APPLY_VACANCY, payload);
}

/**
 * @return {Promise<CalculateOrderResponse>}
 * */
export function calculateOrderRequest() {
    return ApiClient.get(ROUTES.API.CALCULATE_ORDER_REQUEST);
}

/**
 * @param {CreateOrderRequestPayload} payload
 * @return {Promise<CreateOrderRequestResponse>}
 * */
export function createOrderRequest(payload) {
    return ApiClient.post(ROUTES.API.CREATE_ORDER_REQUEST, payload);
}

/**
 * @param {CalculateOrderPayload} payload
 * @return {Promise<CalculateOrderResponse>}
 * */
export function calculateOrder(payload) {
    return ApiClient.get(ROUTES.API.CALCULATE_ORDER, payload);
}

/**
 * @param {CreateOrderPayload} payload
 * @return {Promise<CreateOrderResponse>}
 * */
export function createOrder(payload) {
    return ApiClient.post(ROUTES.API.CREATE_ORDER, payload);
}

/**
 * @param {GetInvoiceStatusPayload} payload
 * @return {Promise<GetInvoiceStatusResponse>}
 * */
export function getInvoiceStatus({ order_number }) {
    return ApiClient.get(ROUTES.API.GET_INVOICE_STATUS, { order_number });
}

/**
 * @param {RegenerateInvoicePayload} payload
 * @return {Promise<{}>}
 * */
export function regenerateInvoice({ order_number }) {
    return ApiClient.post(ROUTES.API.REGENERATE_INVOICE, { order_number });
}

/**
 * @param {ProductRateRequest} payload
 * @return {Promise<ProductRateResponse>}
 */
export function apiProductRate(payload) {
    return ApiClient.post('/api/product/rate', payload);
}

/**
 * @param {ProductRetractRateRequest} payload
 * @return {Promise<ProductRetractRateResponse>}
 */
export function apiRetractProductRate(payload) {
    return ApiClient.post('/api/product/rate/retract', payload);
}
