var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "FilePicker",
      class: { "FilePicker--hasAttachedFile": _vm.isNotEmpty },
    },
    [
      _c("input", {
        ref: "input",
        staticClass: "FilePicker__input",
        attrs: { type: "file", name: _vm.name, id: _vm.id },
        on: { change: _vm.onChange },
      }),
      _vm._v(" "),
      _c("label", {
        staticClass: "FilePicker__label",
        attrs: { for: _vm.id },
        domProps: { textContent: _vm._s(_vm.label) },
      }),
      _vm._v(" "),
      _vm.isNotEmpty
        ? _c("button", {
            staticClass: "FilePicker__clear",
            attrs: { type: "button", "data-uk-close": "" },
            on: { click: _vm.clear },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }