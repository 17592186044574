'use strict'

import get from 'lodash-es/get'
import { Is } from '../../is'
import { Log } from '../../logging'
import { ArgumentError } from '../../errors'
import { existPredicate } from '../../comparators'
import { LibraryLocalization } from '../../../scripts/l10n'
import {
	ApiClientInvalidResponseError,
	ApiClientNetworkError,
	FeipApiServerError,
	FeipApiFatalError,
} from '../../api/error'

/** @enum {string} */
export const ErrorTypes = {
	ClientInvalidResponse: 'ClientInvalidResponse',
	ClientNetwork: 'ClientNetwork',
	Unresolved: 'Unresolved',
	ApiServer: 'ApiServer',
	ApiFatal: 'ApiFatal',
}

/**
 * Feip API error parser for Vue
 * @param {Object} fields
 * @returns {Object} Vue mixin
 */
export default function errorHandler({ fields = {} } = {}) {
	return {
		data() {
			return {
				invalidFields: [],
				invalidFieldsText: {},
			}
		},
		methods: {
			/**
			 * @param {FeipApiServerError} err
			 * @param {Object} payload
			 * @param {string[]} invalidFieldKeys
			 * @param {string} name
			 */
			handleError(err, payload = {}, invalidFieldKeys = [], name = 'General') {
				if (Is.instanceOf(err, FeipApiServerError)) {
					Log.requestError(`${name} request`, payload, err)
					let message = null
					if (Is.array(invalidFieldKeys) && invalidFieldKeys.length > 0) {
						for (const key of invalidFieldKeys) {
							if (err.hasInvalidField(key)) {
								if (Is.function(this.onInvalidField)) this.onInvalidField(key, err)
								message = err.getInvalidField(key).getErrorMessage()
								break
							}
						}
					}
					if (message === null) {
						if (err.isCommon()) {
							message = err.common.getErrorMessage()
						} else if (err.isInvalid()) {
							message = LibraryLocalization.get('ERROR_HANDLER.VALIDATION_ERROR')
						} else {
							message = LibraryLocalization.get('ERROR_HANDLER.COMMON_ERROR')
						}
					}
					this.clearInvalidMessages()
					this.invalidFields = err.getInvalidFieldsKeys()
					this.invalidFieldsText = err.getInvalidFieldsMessages()
					if (Is.function(this.onError)) this.onError(message, err, ErrorTypes.ApiServer)
				} else if (Is.instanceOf(err, FeipApiFatalError)) {
					if (Is.function(this.onError)) this.onError(err.message, err, ErrorTypes.ApiFatal)
					console.error(err)
				} else if (Is.instanceOf(err, ApiClientNetworkError)) {
					if (Is.function(this.onError)) this.onError(err.message, err, ErrorTypes.ClientNetwork)
					console.error(err)
				} else if (Is.instanceOf(err, ApiClientInvalidResponseError)) {
					if (Is.function(this.onError)) this.onError(err.message, err, ErrorTypes.ClientInvalidResponse)
					console.error(err)
				} else  {
					console.error('Unresolved error:\n', err)
				}
			},
			isValidField(fieldName) {
				if (Is.null(fieldName)) throw new ArgumentError('Field name is required')
				this.removeInvalidKey(fieldName)
				let {
					target = null,
					handler = () => null,
					message = () => '',
					proxy = null,
				} = fields[fieldName]
				if (!Is.null(target)) {
					const value = get(this, target)
					if (Is.string(proxy) && get(this, proxy, false)) return true
					else if (Is.function(handler) && handler.call(this, value)) return true
					this.invalidFields.push(fieldName)
					if (Is.function(message)) {
						this.invalidFieldsText[fieldName] = message.call(this, value)
					}
				}
				return false
			},
			removeInvalidKey(key) {
				if (!Is.string(key)) throw new ArgumentError('Key must be string')
				this.invalidFields = this.invalidFields.filter(k => k !== key)
				if (existPredicate(this.invalidFieldsText[key])) delete this.invalidFieldsText[key]
			},
			removeInvalidKeys(keys = []) {
				if (!Is.array(keys)) throw new ArgumentError('Keys must be array')
				keys.forEach(this.removeInvalidKey)
			},
			clearInvalidMessages() {
				this.invalidFields = []
				this.invalidFieldsText = {}
			},
			hasErrorCode(key) {
				return this.invalidFields.includes(key)
			},
			getErrorMessage(key) {
				return this.hasErrorCode(key) ? this.invalidFieldsText[key] : null
			},
		},
	}
}
