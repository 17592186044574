import get from "lodash-es/get";
import { currencyFormatter } from "@library/scripts/currency";
import { Is } from "@library/scripts/is";
import { paginatedItemsSchema } from "./schema";
import { GLOBAL, UI } from "./constants";

export function getFromGlobalScope(path, defaultValue) {
    return get(GLOBAL.APP_ENTRY_POINT, path, defaultValue);
}

export function getFromBinding(path, defaultValue) {
    const prefixedPath = `${GLOBAL.DEFAULT_BINDING_PREFIX}.${path}`;
    return get(GLOBAL.APP_ENTRY_POINT, prefixedPath, defaultValue);
}

export function getImage(sizes, referenceValue = Number.MIN_VALUE) {
    // find image with width nearest to reference value, smallest by default
    const urls = get(
        sizes.reduce(
            (previous, current) =>
                Math.abs(previous.width - referenceValue) <
                Math.abs(current.width - referenceValue)
                    ? previous
                    : current,
            { width: Number.MAX_VALUE }
        ),
        "urls",
        null
    );

    const url = urls.find((item) => item.format === "jpeg");

    return url ? url.url : `${window.location.origin}/uploads/images/placeholders/apex1.png`;
}

export function focusElement(element) {
    if (element) {
        if (Is.function(element.focus)) element.focus();
        if (Is.function(element.select)) element.select();

        if (Is.function(element.getBoundingClientRect)) {
            const rect = element.getBoundingClientRect();
            if (rect) {
                const width =
                    window.innerWidth ||
                    document.documentElement.clientWidth ||
                    document.body.clientWidth;
                // take sticky header height into account
                const offset =
                    width <= UI.STICKY_HEADER_BREAKPOINT
                        ? -20 - UI.STICKY_HEADER_HEIGHT
                        : -20;
                const top = rect.top + window.pageYOffset + offset;
                window.scrollTo({ top, behavior: "smooth" });
            }
        }
    }
}

// the names of the fields in the data from the template
// and in the data received through api are slightly different
export function mapPaginatedItems(
    data,
    { twig = false, itemsPerPage = 4 } = {}
) {
    return paginatedItemsSchema({
        items: get(data, "items", []),
        totalItems: get(data, twig ? "items_total" : "total", 0),
        currentPage: get(data, twig ? "page_current" : "page", 1),
        itemsPerPage: get(data, "page_size", itemsPerPage),
    });
}

// https://stackoverflow.com/questions/36280818/how-to-convert-file-to-base64-in-javascript
export function getBase64FromFile(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}

export function detectOS(userAgent) {
    userAgent = userAgent || navigator.userAgent;

    const ipad = /(iPad).*OS\s([\d_]+)/.test(userAgent);
    const iphone = !ipad && /(iPhone\sOS)\s([\d_]+)/.test(userAgent);
    const android = /(Android);?[\s/]+([\d.]+)?/.test(userAgent);

    return { ios: iphone || ipad, android };
}

export function formatCurrency(kopecks, { removeTrailingZeros = true } = {}) {
    return currencyFormatter(kopecks / 100, 2, "&thinsp;", removeTrailingZeros);
}

// https://stackoverflow.com/questions/4647817/javascript-object-rename-key
export function renameKeys(obj, newKeys) {
    const keyValues = Object.keys(obj).map((key) => {
        const newKey = newKeys[key] || key;
        return { [newKey]: obj[key] };
    });

    return Object.assign({}, ...keyValues);
}

export function removeChildClasses(parentElement, classes) {
    classes.forEach((className) => {
        const elements = parentElement.querySelectorAll(`.${className}`);
        elements.forEach((item) => item.classList.remove(className));
    });
}

export function commonFieldErrorHandler(target) {
    return {
        target,
        handler(v) {
            return v && v.toString().length > 0;
        },
        message() {
            return "Заполните поле";
        },
    };
}

export function scrollToElementWithOffset(element, offset) {
    const y = element.getBoundingClientRect().top + window.pageYOffset + offset;
    window.scrollTo({ top: y, behavior: "smooth" });
}
