import get from 'lodash-es/get';

export default {
    setUserState(state, {
        name = '',
        phone = '',
        email = '',
        loggedIn = false,
    }) {
        state.user = {
            name,
            phone,
            email,
            loggedIn,
        };
    },

    setUserPhone(state, value) {
        state.user.phone = String(value);
    },

    // TODO: typedef
    setCartState(state, cartState) {
        const items = get(cartState, 'items', []);

        state.cart = {
            ...state.cart,

            items,
            items_amount: items.reduce((amount, item) => amount + item.quantity, 0),

            price: get(cartState, 'price', 0),
            price_discounted: get(cartState, 'price_discounted', 0),
            discount: get(cartState, 'discount', 0),
        };
    },

    setStatusModifying(state, flag) {
        state.status.modifying = Boolean(flag);
    },

    setStatusCalculating(state, flag) {
        state.status.calculating = Boolean(flag);
    },

    setStatusOrdering(state, flag) {
        state.status.ordering = Boolean(flag);
    },

    setStatusRedirecting(state, flag) {
        state.status.redirecting = Boolean(flag);
    },

    setStatusGenerating(state, flag) {
        state.status.generating = Boolean(flag);
    },

    // TODO: typedef
    setOrderState(state, orderState) {
        state.order = {
            ...state.order,

            price_total: get(orderState, 'price_total', 0),
            price_total_with_discount: get(orderState, 'price_total_with_discount', 0),
            discount: get(orderState, 'discount', 0),
        };
    },

    clearOrderState(state) {
        state.order = {
            price_total: 0,
            price_total_with_discount: 0,
            discount: 0,
        };
    },

    setCurrentlyEditedLegalEntity(state, entity) {
        state.miscellaneous.currentlyEditedLegalEntity = entity;
    },

    setRecaptchaInitialized(state, value) {
        state.miscellaneous.isRecaptchaInitialized = value;
    },

    setFavoriteProduct(state, value) {
        state.favorites.products[value.productId] = value.flag;
    },

    setFavoriteProductLoading(state, value) {
        state.favorites.productsLoading[value.productId] = value.flag;
    },

    setFavoriteCollection(state, value) {
        state.favorites.collections[value.collectionId] = value.flag;
    },

    setFavoriteCollectionLoading(state, value) {
        state.favorites.collectionsLoading[value.collectionId] = value.flag;
    },
};
