'use strict'

import { Is } from './is'
import { Logic } from './logic'
import { ArgumentError } from './errors'

/** @enum {string} */
export const Directions = {
	Asc: 'asc',
	Desc: 'desc',
}

export class Sort {
	/**
	 * Comparator for string (asc)
	 * @param a {string}
	 * @param b {string}
	 * @returns {number}
	 */
	static stringAsc(a, b) {
		if (!(Is.string(a) && Is.string(b))) throw new ArgumentError('Arguments must be string type')
		return a.localeCompare(b)
	}

	/**
	 * Comparator for string (desc)
	 * @param a {string}
	 * @param b {string}
	 * @returns {number}
	 */
	static stringDesc(a, b) {
		if (!(Is.string(a) && Is.string(b))) throw new ArgumentError('Arguments must be string type')
		return b.localeCompare(a)
	}

	/**
	 * @param {string} key
	 * @param {Directions} direction
	 * @return {function(*, *): number}
	 */
	static arithmeticByKey(key, direction = Directions.Asc) {
		if (!Object.values(Directions).includes(direction)) throw new ArgumentError('Invalid direction')
		return function (a, b) {
			return direction === Directions.Asc
				? a[key] - b[key]
				: b[key] - a[key]
		}
	}

	/**
	 * @param {number} a
	 * @param {number} b
	 * @param {Directions} direction
	 * @return {number}
	 */
	static arithmetic(a, b, direction = Directions.Asc) {
		return direction === Directions.Asc ? a - b : b - a
	}
}

export function existPredicate(value) {
	return Logic.not(Logic.or(Is.null, Is.empty, Is.undefined))(value)
}

/**
 * @param {Array} a1
 * @param {Array} a2
 * @return {boolean}
 */
export function compareArrays(a1, a2) {
	if (a1.length !== a2.length) return false
	for (const item of a1) if (!a2.includes(item)) return false
	return true
}

/**
 * @param {Object} o1
 * @param {Object} o2
 * @return {boolean}
 */
export function compareObjectLength(o1, o2) {
	return Object.keys(o1).length === Object.keys(o2).length
}
